<template>
    <div class="cart my-lg-5" ref="document">
        <div id="element-to-convert">
            <div class="heading">
                <template v-if="isQuotation">
                    <h1>Quotation</h1>
                </template>
                <template v-else>
                    <h1>{{ $dictionary('ordering.heading.sales_heading') }}</h1>
                    <p>{{ $dictionary('ordering.heading.sales_description') }}</p>
                </template>
            </div>

            <div class="addresses row" ref="addresses">
                <div class="col-12 col-md-6 col-lg-3">
                    <address class="box">
                        <span v-html="customer.name"></span> <br>
                        {{ customer.account_number }}
                    </address>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <address class="box">
                        <strong>{{ customer.address.name }}</strong><br>
                        <br>
                        {{ customer.address.street }}
                        {{ customer.address.postal }} {{ customer.address.city }}
                    </address>
                </div>
            </div>

            <div class="row messages">
                <template v-if="importBasketMessages.length > 0">
                    <div v-html="importBasketMessages"></div>
                </template>
                <div v-for="(message, key) in messages" class="col-12" :key="'basket-message-' + key">
                    <div class="alert" v-bind:class="'alert-' + message.level">
                        {{ message.description }}
                    </div>
                </div>
            </div>

            <div class="alert alert-danger" role="alert" v-if="showCreateOrderError">
                {{ $dictionary('ordering.basket.create_order_error') }}
            </div>

            <div v-if="loading">
                <div class="load-text mb-3">
                    {{ $dictionary('catalog.preloader_text') }}
                    <span class="point">.</span>
                    <span class="point">.</span>
                    <span class="point">.</span>
                </div>
            </div>

            <div v-if="!loading">
                <div v-if="hasItems">
                    <div class="cart-list sales">

                        <sales-basket-header/>

                        <sales-basket-line
                                v-for="item of itemList"
                                :key="'basket-line-' + item.productInfo.identifier"
                                v-bind="{
                                basketidentifier,
                                item,
                                stock: getStockForItem(item),
                                prices,
                                currency,
                                isQuotation,
                                createPDF,
                                pricesLoading
                            }"
                        ></sales-basket-line>

                    </div>
                    <div class="cart-summary row">
                        <div class="col-12 col-md-6 mb-4 mb-md-0">
                            <div v-if="!isQuotation" class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6 d-flex align-items-center">
                                            <span>{{ $dictionary('ordering.po_number') }}</span>
                                        </div>
                                        <div class="col-6 po-number">
                                            <input type="text" class="form-control" v-model="poNumber">
                                            <i
                                                    class="icon-question-mark"
                                                    :class="{hovering: hoveringPoIcon}"
                                                    @mouseover="hoveringPoIcon = true"
                                                    @mouseleave="hoveringPoIcon = false"
                                            >?</i>
                                            <span v-if="hoveringPoIcon"
                                                  class="overlay">{{
                                                    $dictionary('ordering.po_number_explanation')
                                                }}</span>
                                        </div>
                                    </div>
                                    <basket-validation-message
                                            v-bind="{
                                            key: 'po_number_validation_message',
                                            showError: invalidFields.includes('po_number'),
                                            message: getValidationMessageForField('ordering.po_number')
                                        }"
                                    ></basket-validation-message>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <span>{{ $dictionary('ordering.partial_delivery') }}</span>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-check form-check-inline">
                                                <input
                                                        v-model="partialDelivery"
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="partial_delivery"
                                                        id="partialDeliveryYes"
                                                        value="1"
                                                        :checked="partialDelivery === '1'"
                                                >
                                                <label class="form-check-label" for="partialDeliveryYes">
                                                    {{ $dictionary('ordering.partial_delivery_option_yes') }}
                                                </label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input
                                                        v-model="partialDelivery"
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="partial_delivery"
                                                        id="partialDeliveryNo"
                                                        value="0"
                                                        :checked="partialDelivery === '0'"
                                                >
                                                <label class="form-check-label" for="partialDeliveryNo">
                                                    {{ $dictionary('ordering.partial_delivery_option_no') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p
                                            v-if="partialDelivery === '0'"
                                            v-html="$dictionary('ordering.full_delivery_explanation')"
                                            class="mt-3"
                                    ></p>
                                    <basket-validation-message
                                            v-bind="{
                                        key: 'partial_delivery',
                                        showError: invalidFields.includes('partial_delivery'),
                                        message: getValidationMessageForField('ordering.partial_delivery')
                                    }"
                                    ></basket-validation-message>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="row">
                                        <div class="col-6 d-flex align-items-center">
                                            <span>{{ $dictionary('ordering.basket.requested.delivery.date') }}</span>
                                        </div>
                                        <div class="col-6">
                                            <flat-pickr
                                                    v-model="deliveryDate"
                                                    :config="config"
                                                    class="form-control input_date"
                                                    :placeholder="$dictionary('reports.basket.date')"
                                                    name="date_from"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-6 offset-lg-1">
                            <basket-summary
                                    v-bind="{
                                    subtotal,
                                    shippingCosts: shipping,
                                    uplift,
                                    totalVat: taxes,
                                    grandTotal,
                                    currency: currency,
                                    organisation: organisation,
                                    isQuotation,
                                    pricesLoading
                                }">
                            </basket-summary>
                        </div>
                    </div>

                    <div v-if="!isQuotation" class="addresses">
                        <h3>{{ $dictionary('ordering.ship_to.heading') }}</h3>

                        <basket-validation-message
                                v-bind="{
                                key: 'ship_to',
                                showError: invalidFields.includes('ship_to'),
                                message: getValidationMessageForField('ordering.ship_to.heading')
                            }"
                        ></basket-validation-message>
                        <ship-to-address-selector
                                v-bind="{
                                addresses: customer.ship_to_addresses,
                                selectedAddressId: shipTo,
                                countries
                            }"
                                v-model="shipTo"
                        />
                    </div>
                </div>
                <p class="alert alert-info mb-5" v-if="!hasItems">{{ $dictionary('ordering.basket.basket_empty') }}</p>
            </div>
            <div v-if="isQuotation">
                <button
                        class="btn btn-primary ripple float-right btn-sm-block"
                        @click="exportToPDF"
                >{{ $dictionary('ordering.basket.export_to_pdf') }}
                </button>
            </div>
            <div v-else>
                <div v-if="!readonly" v-html="cancelbutton"></div>
                <div>
                    <button
                            v-bind:class="{ disabled: checkoutButtonDisabled }"
                            class="btn btn-blue-outline ripple btn-sm-block ml-3"
                            @click="emptyBasket()"
                            :disabled="checkoutButtonDisabled"
                    >{{ $dictionary('ordering.basket.empty_basket') }}
                    </button>
                    <button
                            v-if="!readonly"
                            v-bind:class="{ disabled: checkoutButtonDisabled }"
                            class="btn btn-primary ripple float-right btn-sm-block"
                            v-on:click="checkout()"
                            :disabled="checkoutButtonDisabled"
                    >{{ $dictionary('ordering.basket.checkout') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {serverBus} from '@core/js/serverbus'
import SalesBasketHeader from './SalesBasketHeader'
import _ from 'lodash'
import api from '@/api'
import flatPickr from 'vue-flatpickr-component';
import html2pdf from "html2pdf.js";

export default {
    components: {
        flatPickr,
        salesBasketHeader: SalesBasketHeader,
    },
    props: {
        basketidentifier: String,
        cancelbutton: String,
        formid: String,
        readonly: {
            type: [String, Boolean],
            default: false
        },
        customer: Object,
        countries: [Object, Array],
        confirmationUrl: String,
        organisation: Object,
        importBasketMessages: String
    },
    data: function () {
        return {
            loading: true,
            pricesLoading: true,
            itemList: {},
            hasItems: false,
            orderable: true,
            subtotalLines: null,
            totalLines: null,
            messages: [],
            // SU-specific
            deliveryDateInput: null,
            deliveryDateServer: null,
            config: {
                wrap: false, // set wrap to true only when using 'input-group'
                dateFormat: "Y-m-d",
                disableMobile: "true",
                minDate: "today"
            },
            ordering: false,
            subtotal: null,
            grandTotal: null,
            shipping: null,
            uplift: null,
            taxes: null,
            hoveringPoIcon: false,
            hoveringDcIcon: false,
            partialDeliveryInput: null,
            partialDeliveryServer: null,
            poNumberInput: null,
            poNumberServer: null,
            shipToInput: null,
            shipToServer: null,
            stock: [],
            prices: [],
            invalidFields: [],
            currency: 'EUR',
            createPDF: false,
            showCreateOrderError: false
        }
    },
    watch: {
        isQuotation: function () {
            this.getPrices();
        }
    },
    computed: {
        isQuotation() {
            return store.state.showRecommendedRetailPrice;
        },
        deliveryDate: {
            get() {
                if (this.deliveryDateServer !== null) {
                    return this.deliveryDateServer
                }
                return this.deliveryDateInput
            },
            set: _.debounce(function (value) {
                this.deliveryDateInput = value
            }, 700)
        },
        checkoutButtonDisabled() {
            if (! this.orderable || this.ordering || !this.hasItems) {
                return true
            }

            if (this.prices.length === 0 || this.stock.length === 0) {
                return true
            }

            return false
        },

        partialDelivery: {
            get() {
                if (this.partialDeliveryInput !== null) {
                    return this.partialDeliveryInput
                }

                return this.partialDeliveryServer
            },
            set(value) {
                this.partialDeliveryInput = value
                this.updateBasket()
            }
        },
        poNumber: {
            get() {
                if (this.poNumberInput !== null) {
                    return this.poNumberInput
                }

                return this.poNumberServer
            },
            set: _.debounce(function (value) {
                this.poNumberInput = value
                this.updateBasket()
            }, 700)
        },
        shipTo: {
            get() {
                if (this.shipToInput !== null) {
                    return this.shipToInput
                }

                if (this.shipToServer !== null) {
                    for (const address of this.customer.ship_to_addresses) {
                        if (address['id'] === this.shipToServer) {
                            return this.shipToServer
                        }
                    }
                }

                if (this.customer.ship_to_addresses === null) {
                    return null
                }

                if (this.customer.ship_to_addresses.length === 1) {
                    return this.customer.ship_to_addresses[0]['id'];
                }

                for (const address of this.customer.ship_to_addresses) {
                    if (address['is_default'] === '1') {
                        return address['id']
                    }
                }

                return null;
            },
            set(value) {
                this.shipToInput = value
                this.updateBasket()
            }
        },
        productIds() {
            let productIds = []

            for (let product in this.itemList) {
                productIds.push(this.itemList[product].productInfo.identifier)
            }

            return productIds
        },
        productIdsWithQuantity() {
            let productIdsWithQuantities = []

            for (let product in this.itemList) {
                productIdsWithQuantities.push(
                        this.itemList[product].productInfo.identifier + ':' + this.itemList[product].quantity
                )
            }

            return productIdsWithQuantities
        },
    },
    methods: {
        emptyBasket: function () {
            window.BasketManager.get(this.basketidentifier).clearLines();
        },
        getValidationMessageForField(fieldKey) {
            const fieldTranslation = this.$dictionary(fieldKey)

            return this.$dictionary('ordering.validation_message').replace('%field%', fieldTranslation)
        },
        getStockForItem(item) {
            if (this.stock[item.productInfo.artno]) {
                return this.stock[item.productInfo.artno]
            }

            return null
        },
        validate() {
            this.invalidFields = []
            let valid = true
            if (this.poNumber.length === 0) {
                this.invalidFields.push('po_number')
                valid = false
            }

            if (this.partialDelivery.length === 0) {
                this.invalidFields.push('partial_delivery')
                valid = false
            }

            if (this.shipTo === null || this.shipTo === 0) {
                this.invalidFields.push('ship_to')
                valid = false
            }

            return valid
        },
        updateBasket: function () {
            api.basket.updateBasket(this.basketidentifier, this.poNumber, this.partialDelivery, this.shipTo)
        },
        checkout: function () {
            if (this.orderable && this.validate() && !this.ordering) {
                this.ordering = true
                api.checkout.createOrder(this.basketidentifier, this.organisation.code, this.currency, this.deliveryDate)
                .then((response) => {
                    if (response.data.success) {
                        window.location.href = this.confirmationUrl
                    }
                }).catch((error) => {
                    this.showCreateOrderError = true;
                    this.$nextTick(() => {
                        this.$refs.addresses?.scrollIntoView({behavior: 'smooth'});
                    });
                })
            }
        },
        getStock() {
            api.stock.getStockForProducts(this.productIds).then((result) => {
                this.stock = result.data.data
            })
        },
        getPrices() {
            this.prices = []
            this.subtotal = null
            this.grandTotal = null
            this.shipping = null
            this.uplift = null
            this.taxes = null
            this.currency = null
            this.pricesLoading = true

            if (this.isQuotation) {
                api.pricing.listPriceForProducts(this.productIdsWithQuantity).then((result) => {
                    this.prices = result.data.data
                    this.currency = result.data.data[0].currency
                    let grandTotal = 0;
                    for(let item in result.data.data ) {
                        grandTotal += result.data.data[item].recommended_retail_price * this.getProductQuantity(result.data.data[item].identifier);
                    }
                    this.grandTotal = grandTotal;
                }).finally(() => {
                    this.pricesLoading = false
                })
            } else {
                api.pricing.basketPricesForProducts(this.productIdsWithQuantity).then((result) => {
                    this.prices = result.data.data.items
                    this.subtotal = result.data.data.subtotal
                    this.grandTotal = result.data.data.grand_total
                    this.shipping = result.data.data.shipping_costs
                    this.uplift = result.data.data.uplift
                    this.taxes = result.data.data.vat
                    this.currency = result.data.data.currency
                }).finally(() => {
                    this.pricesLoading = false
                })
            }
        },
        exportToPDF() {
            this.createPDF = true;
            this.$nextTick(() => {
                html2pdf(document.getElementById('element-to-convert'), {
                    margin: [8, 14, 8, 14],
                    jsPDF: {format: 'letter', orientation: 'portrait'}
                });

                this.createPDF = false;
            });
        },
        getProductQuantity(productArtNo) {
            for (let product in this.itemList) {
                if (this.itemList[product].productInfo.artno === productArtNo) {
                    return this.itemList[product].quantity;
                }
            }
            return 0;
        },
    },
    created() {
        // Create a basket for initial load
        window.BasketManager.get(this.basketidentifier);

        // Using the service bus
        serverBus.$on('basket.changed', (basket) => {
            if (basket.identifier === this.basketidentifier) {
                this.itemList = basket.lines
                this.total = basket.getTotal()
                this.subtotalLines = basket.subtotalLines
                this.totalLines = basket.totalLines
                this.hasItems = (basket.getLineCount() > 0)
                this.messages = basket.messages
                this.orderable = true
                // SU Specific
                this.partialDeliveryServer = basket.partial_delivery ? '1' : '0'
                this.poNumberServer = basket.po_number
                this.shipToServer = basket.shipping_address
                this.loading = false

                this.getStock()
                this.getPrices()

                for (var i = 0; i < this.messages.length; i++) {
                    if (this.messages[i].level === 'error') {
                        this.orderable = false;
                    }
                }
            }
        });
    }
}
</script>
