<template>
    <div class="cart-totals">
        <h3>{{ $dictionary('ordering.basket.summary_header') }}</h3>
        <template v-if="!isQuotation">
            <div class="row subtotal-price">
                <div class="col-7 ml-lg-auto">
                    <span>{{ $dictionary('ordering.basket.subtotal')}}</span>
                    <small>{{ $dictionary('ordering.basket.without_vat_text') }}</small>
                </div>

                <div class="col-5 text-right p-0 pr-3">
                    <template v-if="subtotal !== null">
                        {{ formatPrice(subtotal) }}
                    </template>
                    <template v-else-if="pricesLoading">
                        <div class="loader">Loading...</div>
                    </template>
                    <template v-else>
                        <div class="loader-error">Error!</div>
                    </template>
                </div>
            </div>
            <div class="row subtotal-price">
                <div class="col-7 ml-lg-auto">
                    <span>{{ $dictionary('ordering.basket.shipping_costs')}}</span>
                    <small>{{ $dictionary('ordering.basket.without_vat_text') }}</small>
                </div>
                <div class="col-5 text-right p-0 pr-3">
                    <template v-if="shippingCosts !== null">
                        {{ formatPrice(shippingCosts) }}
                    </template>
                    <template v-else-if="pricesLoading">
                        <div class="loader">Loading...</div>
                    </template>
                    <template v-else>
                        <div class="loader-error">Error!</div>
                    </template>
                </div>
            </div>
            <div v-if="uplift !== null" class="row subtotal-price">
                <div class="col-7 ml-lg-auto">
                    <span>{{ $dictionary('ordering.basket.uplift')}}</span>
                    <small>{{ $dictionary('ordering.basket.without_vat_text') }}</small>
                </div>
                <div class="col-5 text-right p-0 pr-3">{{ formatPrice(uplift) }}</div>
            </div>
            <div class="row total-price">
                <div class="col-7 ml-lg-auto">
                    <span>{{ $dictionary('ordering.basket.total_vat')}}</span>
                </div>
                <div class="col-5 text-right p-0 pr-3">
                    <template v-if="totalVat !== null">
                        {{ formatPrice(totalVat) }}
                    </template>
                    <template v-else-if="pricesLoading">
                        <div class="loader">Loading...</div>
                    </template>
                    <template v-else>
                        <div class="loader-error">Error!</div>
                    </template>
                </div>
            </div>
        </template>
        <div class="row grand-total">
            <div class="col-7 ml-lg-auto"><b>{{ $dictionary('ordering.basket.grandtotal') }}</b></div>
            <div class="col-5 text-right p-0 pr-3">
                <template v-if="grandTotal !== null">
                    <b>{{ formatPrice(grandTotal) }}</b>
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import currency from '../../js/helpers/currency'
    export default {
        props: {
            subtotal: {
                type: Number,
                default: null
            },
            shippingCosts: {
                type: Number,
                default: null
            },
            uplift: {
                type: Number,
                default: null
            },
            totalVat: {
                type: Number,
                default: null
            },
            grandTotal: {
                type: Number,
                default: null
            },
            currency: String,
            organisation: Object,
            isQuotation: {
                type: Boolean,
                default: false
            },
            pricesLoading: {
                type: Boolean,
                default: true
            },
        },
        methods: {
            formatPrice: function (price) {
                return currency.formatPrice(price, this.currency)
            }
        }
    }
</script>
