<template>
    <div class="cart-item">
        <div class="item-row">
            <div class="item-description">
                <h4 class="m-0" v-html="item.productInfo.artno"></h4>
                <p class="m-0" v-if="item.productInfo.name" v-html="item.productInfo.name"></p>
            </div>
            <div class="item-stock">
                <item-stock
                    v-bind="{
                        stock: stock !== null ? stock.stock : null,
                        quantity: stock !== null ? stock.quantity : null,
                        nlaStatus: item.productInfo.nla_status,
                        estimatedTimeArrival: item.productInfo.eta,
                        basketAmount: item.quantity
                    }"
                ></item-stock>
            </div>
            <div class="item-remarks">
                <div class="remarks-header">{{ $dictionary('ordering.basket.remarks') }}</div>
                <input type="text" class="form-control" name="remarks" v-model="remark">
            </div>
            <div class="item-quantity text-center">
                <add-to-basket
                    v-bind="{
                        item: item.productInfo.identifier,
                        basketidentifier,
                        orderLimit: item.productInfo.order_limit,
                        disabled: false,
                        itemOrderable: true,
                        small: true,
                        checkOrderLimit: true,
                        minimalOrderQuantity: minimalQuantity,
                        indivisibleUnit: indivisible
                    }"
                    v-bind:item="item.productInfo.identifier"
                    v-bind:basketidentifier="basketidentifier"
                ></add-to-basket>
            </div>
            <div class="item-list-price">
                <template v-if="listPrice !== null">
                    <span class="mobile-header">{{ $dictionary('ordering.basket.list_price') }}</span>
                    <strong v-html="formatPrice(listPrice)"></strong>
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <div class="item-discount">
                <template v-if="discount !== null">
                    <span class="mobile-header">{{ $dictionary('ordering.basket.discount') }}</span>
                    {{ discount }}%
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <div class="item-net-price">
                <template v-if="netPrice !== null">
                    <span class="mobile-header">{{ $dictionary('ordering.basket.net_price') }}</span>
                    <span v-html="formatPrice(netPrice)"></span>
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <div class="item-price-subtotal">
                <template v-if="subTotal !== null">
                    <span class="mobile-header">{{ $dictionary('ordering.basket.subtotal') }}</span>
                    <strong v-html="formatPrice(subTotal)"></strong>
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <div class="item-delete">
                <i class="icon icon-trash-alt-regular" @click="removeLine()"></i>
            </div>
        </div>
        <div
            v-if="item.messages"
            v-for="(message, key) in item.messages" :key="'item-message' + item.id + '-' + key"
        >
            <div class="alert" v-bind:class="'alert-' + message.level">
                {{ message.description }}
            </div>
        </div>
    </div>
</template>

<script>
    import currency from '../../js/helpers/currency'
    import api from '@/api'
    import _ from 'lodash'

    export default {
        props: {
            item: Object,
            prices: {
                type: Array,
                default() {
                    return []
                }
            },
            basketidentifier: String,
            stock: {
                type: Object,
                default: null
            },
            currency: String,
            pricesLoading: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                remarkInput: null,
                remarkServer: null
            }
        },
        computed: {
            price() {
                for (let price of this.prices) {
                    if (price.identifier === this.item.productInfo.artno) {
                        return price
                    }
                }

                return null
            },
            listPrice() {
                if (this.price === null) {
                    return null
                }
                return this.price.gross
            },
            discount() {
                if (this.price === null) {
                    return null
                }

                return this.price.discount_percentage
            },
            netPrice() {
                if (this.price === null) {
                    return null
                }

                return this.price.unit
            },
            subTotal() {
                if (this.price === null) {
                    return null
                }

                return this.price.price
            },
            remark: {
                get() {
                    if (this.remarkInput !== null) {
                        return this.remarkInput
                    }

                    return this.remarkServer
                },
                set: _.debounce(function(value) {
                    this.remarkInput = value
                    this.updateRemark()
                }, 700)
            },
            minimalQuantity() {
                if (this.item.productInfo.minimal_order_quantity === null || this.item.productInfo.minimal_order_quantity === 0 || this.item.productInfo.minimal_order_quantity === undefined) {
                    return null
                }
                else {
                    return this.item.productInfo.minimal_order_quantity
                }
            },
            indivisible() {
                if (this.item.productInfo.indivisible_unit === null || this.item.productInfo.indivisible_unit === 0 || this.item.productInfo.indivisible_unit === undefined) {
                    return null
                }
                else {
                    return this.item.productInfo.indivisible_unit
                }
            }
        },
        methods: {
            updateRemark: function () {
                api.basket.updateRemark(this.item.identifier, this.remark)
            },
            removeLine: function () {
                window.BasketManager.get(this.basketidentifier).removeLine(this.item.productInfo.identifier);
            },
            formatPrice: function (price) {
                if (price == null || typeof price === 'undefined') {
                    return
                }

                return currency.formatPrice(price, this.currency)
            }
        },
        created() {
            this.remarkServer = this.item.remark
        }
    }
</script>
