<template>
    <div class="add-to-basket d-flex align-items-center justify-content-center">
        <template v-if="isOrderable">

            <Transition name="fade">
                <div v-if="errorMessage" class="quantity-message alert alert-warning alert-dismissible show" role="alert">
                    <span v-if="errorMessageQuantity">{{ quantityErrorMessage }}</span>
                    <span v-if="errorMessageDelivery">{{ deliveryErrorMessage }}</span>
                    <button type="button" class="close" @click="errorMessage = ! errorMessage" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </Transition>

            <div v-if="inBasket" class="quantity-selector d-flex">
                <button v-on:click="decreaseLineQuantity();" class="btn btn-spinner ripple btn-decrease">-</button>
                <input class="text-center" type="number" v-on:change="setBasketQuantity();" min="0" v-model="size">
                <button v-on:click="increaseLineQuantity();" class="btn btn-spinner ripple btn-increase">+</button>
            </div>

            <div v-else class="button-wrapper">
                <button v-on:click="addToBasket();"
                        class="btn btn-secondary btn-add-to-cart"
                        v-bind:class="{
                        'd-none': (inBasket && !basketUpdated),
                        'animate spaceOutUp': basketUpdated,
                        'animate fadeIn': !inBasket,
                        'btn-small': small
                    }"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>

        <template v-else>
            <div class="button-wrapper">
                <div class="btn btn-loading loading"
                    v-bind:class="{
                        'btn-small': small,
                        'btn-no-price': noPrice,
                        'btn-no-nla': nlaStatus,
                        'btn-no-quantity': !minimalOrderQuantity,
                        'btn-not-indivisible': !indivisibleUnit,
                        'btn-no-preloader': removePreLoader
                    }">{{ buttonText }}</div>
            </div>
        </template>
    </div>
</template>

<script>
    import coreHelper from '@core/js/helpers'

    export default {
        name: 'AddToBasket',
        props: {
            text: String,
            item: [Number, String],
            basketidentifier: String,
            nlaStatus: {
                type: String,
                default: ''
            },
            small: {
                type: [String, Boolean],
                default: false
            },
            orderLimit: {
                type: [String, Number],
                default: null
            },
            itemOrderable: [Boolean, String],
            checkOrderLimit: {
                type: Boolean,
                default: false
            },
            noPrice: Boolean,
            removePreLoader: {
                type: Boolean,
                default: false
            },
            minimalOrderQuantity: {
                type: Number,
                default: 1
            },
            indivisibleUnit: {
                type: Number,
                default: 1
            }
        },
        data: function () {
            return {
                basketUpdated: false,
                size: 0,
                updating: 0,
                needUpdate: false,
                errorMessage: false,
                errorMessageQuantity: false,
                errorMessageDelivery: false,
            }
        },
        computed: {
            isOrderable() {
                if (this.minimalOrderQuantity === null || this.minimalOrderQuantity === 0) {
                    return false
                }
                if (this.indivisibleUnit === null || this.indivisibleUnit === 0) {
                    return false
                }
                return this.itemOrderable
            },
            reachedOrderLimit() {
                if (this.orderLimit === null) {
                    return false
                }

                return this.size >= this.orderLimit
            },
            buttonText() {
                if (this.small) {
                    return this.dictionary('ordering.button.order.small')
                }

                return this.dictionary('ordering.button.order')
            },
            inBasket() {
               return this.size > 0
            },
            quantityErrorMessage() {
                return this.dictionary('ordering.error.quantity').replace('%field%', this.minimalOrderQuantity)
            },
            deliveryErrorMessage() {
                return this.dictionary('ordering.error.delivery').replace('%field%', this.indivisibleUnit)
            }
        },

        watch:{
            errorMessage(val){
                if (val){
                    setTimeout(()=>this.errorMessage=false,2500);
                    setTimeout(()=>this.errorMessageQuantity=false,2500);
                    setTimeout(()=>this.errorMessageDelivery=false,2500);
                }
            }
        },

        methods: {
            dictionary(key) {
                return coreHelper.dictionary.get(key)
            },
            fmod: function(a,b) {
                return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
            },

            addToBasket: function () {
                this.size = this.size > 0 ? parseInt(this.size) : this.minimalOrderQuantity;

                if (this.checkOrderLimit && this.reachedOrderLimit) {
                    this.updating--;

                    return
                }
                var basket = window.BasketManager.get(this.basketidentifier);

                if (this.minimalOrderQuantity > 1) {
                    basket.addLine(this.item, this.minimalOrderQuantity);
                    this.updating++;
                    return
                }

                basket.addLine(this.item, 1);
                this.updating++;
            },

            setBasketQuantity: function (event) {
                if (!this.updating) {
                    var basket = window.BasketManager.get(this.basketidentifier);
                    //CHECK IF MINIMAL ORDER QUANTITY IS CORRECT
                    if (this.size === 0) {
                        basket.set(this.item, parseFloat(this.size));
                        this.updating++;
                        return
                    }
                    
                    if (this.size < this.minimalOrderQuantity) {
                        this.errorMessageDelivery = false;
                        this.size = this.minimalOrderQuantity;
                        basket.set(this.item, parseFloat(this.size));
                        this.updating++;
                        
                        //SHOW DELIVERY ERROR MESSAGE
                        this.errorMessage = true;
                        this.errorMessageQuantity = true;
                        
                        return
                    }
                    //CHECK IF DELIVERY QUANTITY MATCHES
                    if (this.indivisibleUnit > 1) {
                        this.errorMessageQuantity = false;
                        if (this.fmod(this.size, this.indivisibleUnit) !== 0) {
                            this.size--;
                            basket.set(this.item, parseFloat(this.size));

                            //SHOW DELIVERY ERROR MESSAGE
                            this.errorMessage = true;
                            this.errorMessageDelivery = true;
                            return
                        }
                        else {
                            basket.set(this.item, parseFloat(this.size));
                        }
                    }
                    else {
                        basket.set(this.item, parseFloat(this.size));
                        this.updating++;
                    }
                    
                } else {
                    this.needUpdate = true;
                }
            },

            decreaseLineQuantity: function () {
                if (this.indivisibleUnit > 1 && this.size > 0) {
                    this.size = this.size - this.indivisibleUnit;
                    if (this.size < this.minimalOrderQuantity) {
                        this.size = 0;
                        this.setBasketQuantity();
                        return
                    }
                    this.setBasketQuantity();
                    return
                }
                if (this.size > 0) {
                    this.size--;
                    this.setBasketQuantity();
                }
            },

            increaseLineQuantity: function () {
                if (this.indivisibleUnit > 1) {
                    this.size = this.size + this.indivisibleUnit;
                    this.setBasketQuantity();
                    return
                }
                if (this.checkOrderLimit && this.orderLimit !== null && this.size >= this.orderLimit) {
                    return
                }
                this.size++;
                this.setBasketQuantity();
            },

            sendChanges: function () {
                var basket = window.BasketManager.get(this.basketidentifier);
                if (basket.getQuantityForItem(this.item) != parseFloat(this.size)) {
                    basket.set(this.item, parseFloat(this.size));
                    this.updating++;
                }
            }
        },
        created() {
            // Create a basket for initial load
            var basket = window.BasketManager.get(this.basketidentifier);

            this.size = basket.getQuantityForItem(this.item);

            if (this.inBasket == false) {
                this.basketUpdated = false;
            }

            // Using the service bus
            window.serverBus.$on('basket.changed', (basket) => {
                if (basket.identifier == this.basketidentifier) {
                    if (this.inBasket == false) {
                        this.basketUpdated = false;
                    }

                    if (this.needUpdate) {
                        this.sendChanges();
                    }

                    if (this.updating > 0) {
                        this.updating--;
                    }

                    if (this.updating === 0) {
                        this.size = basket.getQuantityForItem(this.item);
                    }
                    this.needUpdate = false;
                }
            });
        },
    }
</script>
