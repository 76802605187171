<template>
    <div>
        <add-to-basket
            v-bind="{
                item: productId,
                basketidentifier,
                disabled: ! orderable,
                itemOrderable: orderable,
                orderLimit: orderLimit,
                noPrice: this.noPrice,
                minimalOrderQuantity: minimalQuantity,
                indivisibleUnit: indivisible
            }"
        ></add-to-basket>

        <item-stock-sales
            v-if="!hideStock"
            :artno="artno"
        ></item-stock-sales>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        productId: Number,
        orderLimit: [Number, String],
        artno: String,
        basketidentifier: String,
        hideStock: String,
        minimalOrderQuantity: Number,
        indivisibleUnit: Number,
    },
    data() {
        return {
            noPrice: false
        }
    },
    computed: {
        ...mapGetters('lister', [
            'prices',
            'pricesLoading'
        ]),
        price() {
            for (const priceObj of this.prices) {
                if (priceObj.identifier === this.artno) {
                    return priceObj
                }
            }

            return null
        },
        grossPrice() {
            if (this.price == null) {
                return null
            }

            if (! this.price.hasOwnProperty('gross_price')) {
                return null
            }

            if (this.price.gross_price === null) {
                this.noPrice = true
            }
            return this.price.gross_price
        },
        orderable() {
            return this.grossPrice !== null
        },

        minimalQuantity() {
            if (this.minimalOrderQuantity === null || this.minimalOrderQuantity === 0) {
                return null
            }
            else {
                return this.minimalOrderQuantity
            }
        },
        indivisible() {
            if (this.indivisibleUnit === null || this.indivisibleUnit === 0) {
                return null
            }
            else {
                return this.indivisibleUnit
            }
        }
    },
    watch: {
        pricesLoading(loading) {
            if (!loading && this.grossPrice === null) {
                this.noPrice = true
            }
        }
    }
}
</script>
