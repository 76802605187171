<template>
    <div class="cart-item">
        <div class="item-row">
            <div class="item-product-image">
                <img v-bind:src="item.productInfo.image_url" v-bind:alt="item.productInfo.name" class=" img-fluid">
            </div>
            <div class="item-description">
                <h4 class="m-0 pr-4" v-html="item.productInfo.artno"></h4>
                <p class="m-0" v-if="item.productInfo.name" v-html="item.productInfo.name"></p>
            </div>
            <div class="item-quantity text-center">
                <template v-if="createPDF">
                    <p v-html="item.quantity"></p>
                </template>
                <template v-else>
                    <add-to-basket
                            v-bind="{
                        item: item.productInfo.identifier,
                        basketidentifier,
                        orderLimit: item.productInfo.order_limit,
                        disabled: false,
                        itemOrderable: true,
                        checkOrderLimit: true,
                        minimalOrderQuantity: minimalQuantity,
                        indivisibleUnit: indivisible
                    }"
                            v-bind:item="item.productInfo.identifier"
                            v-bind:basketidentifier="basketidentifier"
                    ></add-to-basket>
                </template>
            </div>
            <div class="item-price-unit">
                <template v-if="netPrice !== null">
                    <span class="mobile-header">{{ $dictionary('ordering.basket.price_unit') }}</span>
                    <strong v-html="formatPrice(netPrice)"></strong>
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <div class="item-price-subtotal">
                <template v-if="subTotal !== null">
                    <span class="mobile-header">{{ $dictionary('ordering.basket.subtotal') }}</span>
                    <strong v-html="formatPrice(subTotal)"></strong>
                </template>
                <template v-else-if="pricesLoading">
                    <div class="loader">Loading...</div>
                </template>
                <template v-else>
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <div class="item-delete" data-html2canvas-ignore>
                <i class="icon icon-trash-alt-regular" @click="removeLine()"></i>
            </div>
        </div>
        <div
            v-if="item.messages"
            v-for="(message, key) in item.messages" :key="'item-message' + item.id + '-' + key"
        >
            <div class="alert" v-bind:class="'alert-' + message.level">
                {{ message.description }}
            </div>
        </div>
    </div>
</template>

<script>
    import currency from '../../js/helpers/currency'
    import api from '@/api'
    import _ from 'lodash'

    export default {
        props: {
            item: Object,
            prices: {
                type: Array,
                default() {
                    return []
                }
            },
            basketidentifier: String,
            stock: {
                type: Object,
                default: null
            },
            modelValue: String,
            currency: String,
            isQuotation: Boolean,
            createPDF: Boolean,
            pricesLoading: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                remarkInput: null,
                remarkServer: null
            }
        },
        computed: {
            price() {
                for (let price of this.prices) {
                    if (price.identifier === this.item.productInfo.artno) {
                        return price
                    }
                }

                return null
            },
            netPrice() {
                if (this.price === null) {
                    return null
                }

                if (this.isQuotation) {
                    return this.price.recommended_retail_price
                }

                return this.price.unit
            },
            subTotal() {
                if (this.price === null) {
                    return null
                }

                if (this.isQuotation) {
                    return this.price.recommended_retail_price * this.item.quantity;
                }

                return this.price.price
            },
            remark: {
                get() {
                    if (this.remarkInput !== null) {
                        return this.remarkInput
                    }

                    return this.remarkServer
                },
                set: _.debounce(function(value) {
                    this.remarkInput = value
                    this.updateRemark()
                }, 700)
            },
            minimalQuantity() {
                if (this.item.productInfo.minimal_order_quantity === null || this.item.productInfo.minimal_order_quantity === 0 || this.item.productInfo.minimal_order_quantity === undefined) {
                    return null
                }
                else {
                    return this.item.productInfo.minimal_order_quantity
                }
            },
            indivisible() {
                if (this.item.productInfo.indivisible_unit === null || this.item.productInfo.indivisible_unit === 0 || this.item.productInfo.indivisible_unit === undefined) {
                    return null
                }
                else {
                    return this.item.productInfo.indivisible_unit
                }
            }
        },
        methods: {
            updateRemark: function () {
                api.basket.updateRemark(this.item.identifier, this.remark)
            },
            removeLine: function () {
                window.BasketManager.get(this.basketidentifier).removeLine(this.item.productInfo.identifier);
            },
            formatPrice: function (price) {
                if (price == null || typeof price === 'undefined') {
                    return
                }

                return currency.formatPrice(price, this.currency)
            }
        },
        created() {
            this.remarkServer = this.item.remark
        }
    }
</script>
