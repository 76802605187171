var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row result no-gutters",
      class: { alternative: _vm.isAlternative },
    },
    [
      _c("div", { staticClass: "itemnr col-6 col-md-4 col-lg-2" }, [
        _vm._v("\n        " + _vm._s(_vm.item.info.artno) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "description col-6 col-md-4 col-lg-3" }, [
        _vm._v("\n        " + _vm._s(_vm.name) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quantity text-center col-12 col-md-4 col-lg-2" },
        [
          _c(
            "add-to-basket",
            _vm._b(
              { attrs: { small: "1" } },
              "add-to-basket",
              {
                item: _vm.item.id,
                basketidentifier: _vm.basketidentifier,
                disabled: !_vm.orderable,
                itemOrderable: _vm.orderable,
                orderLimit: _vm.orderLimit,
                checkOrderLimit: true,
                nlaStatus: _vm.nlaStatus,
                minimalOrderQuantity: _vm.minimalQuantity,
                indivisibleUnit: _vm.indivisible,
              },
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-12 col-lg-5 mt-md-3 mt-md-0 mt-3 mb-2 mb-md-0 mt-lg-0",
          class: { alternative: _vm.isAlternative },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "item-stock",
                _vm._b(
                  {},
                  "item-stock",
                  {
                    stock: _vm.stockObj ? _vm.stockObj.stock : null,
                    quantity: _vm.stockObj ? _vm.stockObj.quantity : null,
                    nlaStatus: _vm.nlaStatus,
                    estimatedTimeArrival: _vm.estimatedTimeArrival,
                    basketAmount: _vm.basketAmount,
                  },
                  false
                )
              ),
              _vm._v(" "),
              _vm.nlaStatus
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "netprice col-4 col-md-2",
                        class: { greyedOut: _vm.nlaStatus },
                      },
                      [_vm._v("\n                    -\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grossprice col-5 col-md-2",
                        class: { greyedOut: _vm.nlaStatus },
                      },
                      [_vm._v("\n                    -\n                ")]
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "netprice col-4 col-md-2" },
                      [
                        _vm.netPrice !== null
                          ? [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.formatPrice(_vm.netPrice)) +
                                  "\n                    "
                              ),
                            ]
                          : _vm.pricesLoading
                          ? [
                              _c("div", { staticClass: "loader" }, [
                                _vm._v("Loading..."),
                              ]),
                            ]
                          : [
                              _c("div", { staticClass: "loader-error" }, [
                                _vm._v("Error!"),
                              ]),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "grossprice col-5 col-md-2" },
                      [
                        _vm.grossPrice !== null
                          ? [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.formatPrice(_vm.grossPrice)) +
                                  "\n                    "
                              ),
                            ]
                          : _vm.pricesLoading
                          ? [
                              _c("div", { staticClass: "loader" }, [
                                _vm._v("Loading..."),
                              ]),
                            ]
                          : [
                              _c("div", { staticClass: "loader-error" }, [
                                _vm._v("Error!"),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ],
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "orderable text-left text-md-center col-2 col-md-2",
                },
                [
                  _c("i", {
                    staticClass: "icon",
                    class: {
                      "icon-check-solid c-info-green": _vm.orderable,
                      "icon-times-solid c-info-red": !_vm.orderable,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "eta text-left text-md-center col-4 offset-1 offset-md-0 col-md-1 my-2 my-md-0",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.estimatedTimeArrival) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "nla text-left text-md-center col-5 col-md-2 my-2 my-md-0",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.nlaStatus) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "orderlimit text-center col-2 col-md-2 my-2 my-md-0",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.orderLimit) +
                      "\n            "
                  ),
                ]
              ),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }