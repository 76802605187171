var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card product-card",
      attrs: { id: "product" + _vm.product.id },
    },
    [
      _c(
        "a",
        {
          staticClass: "card-img-holder text-center",
          attrs: { href: _vm.detailUrl },
        },
        [
          _c("img", {
            staticClass: "card-img-top img-fluid",
            attrs: { src: _vm.image, alt: _vm.productName },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body d-flex flex-column" },
        [
          _c("h4", { staticClass: "card-title" }, [
            _c("a", {
              attrs: { title: _vm.product.info.artno, href: _vm.detailUrl },
              domProps: { innerHTML: _vm._s(_vm.product.info.artno) },
            }),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "item-title" }, [
            _c("a", {
              attrs: { title: _vm.productName, href: _vm.detailUrl },
              domProps: { innerHTML: _vm._s(_vm.productName) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "price",
              class: {
                loading: _vm.pricesLoading,
              },
            },
            [
              _vm.grossPrice !== null ||
              (_vm.showRecommendedRetailPrice && _vm.rrpPrice !== null)
                ? [
                    _vm.showRecommendedRetailPrice
                      ? _c("div", [
                          _vm.rrpPrice !== null
                            ? _c("span", { staticClass: "SalePrice" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.formatPrice(_vm.rrpPrice)) +
                                    " "
                                ),
                                _c("span", { staticClass: "price-vat" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$dictionary("ordering.price.inc.rrp")
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _vm.grossPrice !== null
                            ? _c("span", { staticClass: "SalePrice" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.formatPrice(_vm.grossPrice)) +
                                    " "
                                ),
                                _c("span", { staticClass: "price-vat" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$dictionary("ordering.price.vat")
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                  ]
                : !_vm.pricesLoading
                ? [
                    _c("div", { staticClass: "loader-error" }, [
                      _vm._v("Error!"),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "add-to-basket",
            _vm._b(
              {},
              "add-to-basket",
              {
                item: _vm.product.id,
                basketidentifier: _vm.basketidentifier,
                disabled: !_vm.orderable,
                itemOrderable: _vm.orderable,
                orderLimit: _vm.product.info.order_limit,
                noPrice: !_vm.pricesLoading && this.noPrice,
                minimalOrderQuantity: this.minimalOrderQuantity,
                indivisibleUnit: this.indivisibleUnit,
              },
              false
            )
          ),
          _vm._v(" "),
          !_vm.hideStock
            ? _c(
                "item-stock-sales",
                _vm._b(
                  {},
                  "item-stock-sales",
                  {
                    artno: _vm.product.info.artno,
                  },
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }