<template>
    <div class="price d-flex" v-if="pricesLoading || noPrice || grossPrice !== null || (showRecommendedRetailPrice && recommendedRetailPrice !== null)" :class="{
        'loading': pricesLoading,
        'no-price': noPrice
        }">
        <h5 v-if="noPrice" class="greyedOut">
            <span class="price-vat">{{ $dictionary('ordering.price.vat') }}</span>
        </h5>
        <h5 v-else>
            <div v-if="showRecommendedRetailPrice">
                <span>{{ recommendedRetailPrice }}</span> <span class="price-vat">{{ $dictionary('ordering.price.inc.rrp') }}</span>
            </div>
            <div v-else>
                <span>{{ grossPrice }}</span> <span class="price-vat">{{ $dictionary('ordering.price.vat') }}</span>
            </div>
        </h5>
    </div>
    <div v-else class="loader-error">Error!</div>
</template>

<script>

import currency from '@/js/helpers/currency'
import {mapGetters} from "vuex";

export default {
    props: {
        productId: Number,
        identifier: String,
    },
    data() {
        return {
            noPrice: false
        }
    },
    computed: {
        ...mapGetters('lister', [
            'prices',
            'pricesLoading'
        ]),
        showRecommendedRetailPrice() {
            return store.state.showRecommendedRetailPrice && this.recommendedRetailPrice !== null
        },
        price() {
            for (const priceObj of this.prices) {
                if (priceObj.identifier === this.identifier) {
                    return priceObj
                }
            }

            return null
        },
        recommendedRetailPrice() {
            if (this.price == null) {
                return null
            }

            if (! this.price.hasOwnProperty('recommended_retail_price')) {
                return null
            }

            if (this.price.recommended_retail_price > 0 ){
                return currency.formatPrice(this.price.recommended_retail_price, this.price.currency)
            }
            return null
        },
        grossPrice() {
            if (this.price == null) {
                return null
            }

            if (! this.price.hasOwnProperty('price_inc')) {
                return null
            }

             if (this.price.price_inc === null) {
                this.noPrice = true
            }

            return currency.formatPrice(this.price.price_inc, this.price.currency)
        }
    }
}
</script>
