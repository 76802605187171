var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-item" },
    [
      _c("div", { staticClass: "item-row" }, [
        _c("div", { staticClass: "item-description" }, [
          _c("h4", {
            staticClass: "m-0",
            domProps: { innerHTML: _vm._s(_vm.item.productInfo.artno) },
          }),
          _vm._v(" "),
          _vm.item.productInfo.name
            ? _c("p", {
                staticClass: "m-0",
                domProps: { innerHTML: _vm._s(_vm.item.productInfo.name) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-stock" },
          [
            _c(
              "item-stock",
              _vm._b(
                {},
                "item-stock",
                {
                  stock: _vm.stock !== null ? _vm.stock.stock : null,
                  quantity: _vm.stock !== null ? _vm.stock.quantity : null,
                  nlaStatus: _vm.item.productInfo.nla_status,
                  estimatedTimeArrival: _vm.item.productInfo.eta,
                  basketAmount: _vm.item.quantity,
                },
                false
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item-remarks" }, [
          _c("div", { staticClass: "remarks-header" }, [
            _vm._v(_vm._s(_vm.$dictionary("ordering.basket.remarks"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.remark,
                expression: "remark",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", name: "remarks" },
            domProps: { value: _vm.remark },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.remark = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-quantity text-center" },
          [
            _c(
              "add-to-basket",
              _vm._b(
                {
                  attrs: {
                    item: _vm.item.productInfo.identifier,
                    basketidentifier: _vm.basketidentifier,
                  },
                },
                "add-to-basket",
                {
                  item: _vm.item.productInfo.identifier,
                  basketidentifier: _vm.basketidentifier,
                  orderLimit: _vm.item.productInfo.order_limit,
                  disabled: false,
                  itemOrderable: true,
                  small: true,
                  checkOrderLimit: true,
                  minimalOrderQuantity: _vm.minimalQuantity,
                  indivisibleUnit: _vm.indivisible,
                },
                false
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-list-price" },
          [
            _vm.listPrice !== null
              ? [
                  _c("span", { staticClass: "mobile-header" }, [
                    _vm._v(
                      _vm._s(_vm.$dictionary("ordering.basket.list_price"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("strong", {
                    domProps: {
                      innerHTML: _vm._s(_vm.formatPrice(_vm.listPrice)),
                    },
                  }),
                ]
              : _vm.pricesLoading
              ? [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])]
              : [
                  _c("div", { staticClass: "loader-error" }, [
                    _vm._v("Error!"),
                  ]),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-discount" },
          [
            _vm.discount !== null
              ? [
                  _c("span", { staticClass: "mobile-header" }, [
                    _vm._v(_vm._s(_vm.$dictionary("ordering.basket.discount"))),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.discount) +
                      "%\n            "
                  ),
                ]
              : _vm.pricesLoading
              ? [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])]
              : [
                  _c("div", { staticClass: "loader-error" }, [
                    _vm._v("Error!"),
                  ]),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-net-price" },
          [
            _vm.netPrice !== null
              ? [
                  _c("span", { staticClass: "mobile-header" }, [
                    _vm._v(
                      _vm._s(_vm.$dictionary("ordering.basket.net_price"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.formatPrice(_vm.netPrice)),
                    },
                  }),
                ]
              : _vm.pricesLoading
              ? [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])]
              : [
                  _c("div", { staticClass: "loader-error" }, [
                    _vm._v("Error!"),
                  ]),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-price-subtotal" },
          [
            _vm.subTotal !== null
              ? [
                  _c("span", { staticClass: "mobile-header" }, [
                    _vm._v(_vm._s(_vm.$dictionary("ordering.basket.subtotal"))),
                  ]),
                  _vm._v(" "),
                  _c("strong", {
                    domProps: {
                      innerHTML: _vm._s(_vm.formatPrice(_vm.subTotal)),
                    },
                  }),
                ]
              : _vm.pricesLoading
              ? [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])]
              : [
                  _c("div", { staticClass: "loader-error" }, [
                    _vm._v("Error!"),
                  ]),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item-delete" }, [
          _c("i", {
            staticClass: "icon icon-trash-alt-regular",
            on: {
              click: function ($event) {
                return _vm.removeLine()
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.item.messages, function (message, key) {
        return _vm.item.messages
          ? _c("div", { key: "item-message" + _vm.item.id + "-" + key }, [
              _c(
                "div",
                { staticClass: "alert", class: "alert-" + message.level },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(message.description) +
                      "\n        "
                  ),
                ]
              ),
            ])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }