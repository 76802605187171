<template>
    <div class="btn-group minibasket-btn-group">
        <a v-if="showRecommendedRetailPrice"
            v-bind:href="url"
            type="button"
            class="btn btn-text dropdown-toggle btn-mini-basket"
        >
            <i class="icon icon-cart"></i>
            <span v-if="size" v-bind:class="{ 'animate puffIn': size }" class="basket-counter">{{ size }}</span>
        </a>
        <button v-else
            type="button"
            class="btn btn-text dropdown-toggle btn-mini-basket"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <i class="icon icon-cart"></i>
            <span v-if="size" v-bind:class="{ 'animate puffIn': size }" class="basket-counter">{{ size }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <h3 class="bold text-uppercase h6">{{ $dictionary('ordering.basket.minibaskettitle') }}</h3>
            <div v-if="hasItems" class="mini-basket-items">
                <ul class="list-group list-group-flush">
                    <li v-for="item in itemList" :key="item.productInfo.identifier" class="list-group-item px-0">
                        <div class="item-quantity">{{ item.getQuantity() }}&times;</div>
                        <div class="item-name" v-html="item.productInfo.name"></div>
                        <div class="item-price pr-2">
                            <template v-if="hasPrices">
                                <span class="price d-block text-right bold">
                                   {{ getPrice(item) }}
                                </span>
                            </template>
                            <template v-else-if="pricesLoading">
                                <div class="loader">Loading...</div>
                            </template>
                            <template v-else>
                                <div class="loader-error">Error!</div>
                            </template>
                        </div>
                    </li>
                </ul>
                <div class="total mt-3 clearfix">
                    <span class="float-left bold">{{ $dictionary('ordering.basket.subtotal') }}</span>
                    <span class="float-right bold pr-2">
                        <template v-if="subtotal">
                            {{ formatPrice(subtotal) }}
                        </template>
                       <template v-else-if="pricesLoading">
                            <div class="loader">Loading...</div>
                        </template>
                        <template v-else>
                            <div class="loader-error">Error!</div>
                        </template>
                    </span>
                </div>
                <div class="total mt-3 clearfix">
                    <span class="float-left bold">{{ $dictionary('ordering.basket.tax') }}</span>
                    <span class="float-right bold pr-2">
                        <template v-if="taxes !== null">
                            {{ formatPrice(taxes) }}
                        </template>
                        <template v-else-if="pricesLoading">
                            <div class="loader">Loading...</div>
                        </template>
                        <template v-else>
                            <div class="loader-error">Error!</div>
                        </template>
                    </span>
                </div>
                <div class="total mt-3 clearfix">
                    <span class="float-left bold">{{ $dictionary('ordering.basket.grandtotal') }}</span>
                    <span class="float-right bold pr-2">
                        <template v-if="grandTotal">
                            {{ formatPrice(grandTotal) }}
                        </template>
                        <template v-else-if="pricesLoading">
                            <div class="loader">Loading...</div>
                        </template>
                        <template v-else>
                            <div class="loader-error">Error!</div>
                        </template>
                    </span>
                </div>
                <a class="btn btn-primary ripple btn-block mt-2" v-bind:href="url">{{ $dictionary('ordering.basket.viewcart') }}</a>
            </div>
            <p v-if="!hasItems" class="alert alert-info">{{ $dictionary('ordering.basket.basket_empty') }}</p>
        </div>
    </div>
</template>
<script>
    import {serverBus} from '@core/js/serverbus'
    import api from '@/api'
    import currency from '../../js/helpers/currency'

    export default {
        name: 'MiniBasket',
        props: {
            url: String,
            basketidentifier: String
        },
        data: function () {
            return {
                itemList: {},
                size: 0,
                opened: false,
                prices: [],
                subtotal: null,
                grandTotal: null,
                shipping: null,
                uplift: null,
                taxes: null,
                needsUpdate: true,
                pricesLoading: true
            }
        },
        watch: {
            opened: function(newValue, oldValue) {
                if (newValue && this.needsUpdate) {
                    this.getPrices()
                }
            }
        },
        computed: {
            productIdsWithQuantity() {
                let productIdsWithQuantities = []

                for (const product in this.itemList) {
                    productIdsWithQuantities.push(
                        this.itemList[product].productInfo.identifier + ':' + this.itemList[product].quantity
                    )
                }

                return productIdsWithQuantities
            },
            showRecommendedRetailPrice() {
                return store.state.showRecommendedRetailPrice;
            },
            hasPrices() {
                return this.prices.length > 0
            },
            hasItems() {
                return this.size > 0
            },
            currency() {
                return this.prices[0]?.currency;
            }
        },
        methods: {
            getPrice(item) {
                for (const price of this.prices) {
                    if (price.identifier === item.productInfo.artno) {
                        return currency.formatPrice(price.price, price.currency)
                    }
                }

                return null
            },
            formatPrice: function(price) {
                return currency.formatPrice(price, this.currency)
            },
            getItemCount: function() {
                this.size = 0;
                for (var key in this.itemList) {
                    this.size += this.itemList[key].quantity;
                }
            },
            getPrices() {
                api.pricing.miniBasketPricesForProducts(this.productIdsWithQuantity).then((result) => {
                    this.prices = result.data.data.items
                    this.subtotal = result.data.data.subtotal
                    this.grandTotal = result.data.data.grand_total
                    this.shipping = result.data.data.shipping_costs
                    this.uplift = result.data.data.uplift
                    this.taxes = result.data.data.vat

                    this.getItemCount();
                    this.needsUpdate = false
                }).finally(() => {
                    this.pricesLoading = false
                })
            }
        },
        created() {
            window.BasketManager.get(this.basketidentifier);

            // Using the service bus
            serverBus.$on('basket.changed', (basket) => {
                this.itemList = basket.lines
                this.getItemCount();

                if (this.opened) {
                    this.getPrices()
                } else {
                    this.needsUpdate = true
                }
            });

            let that = this
            $(document).ready(function() {
                $('.minibasket-btn-group').on('show.bs.dropdown', function () {
                    that.opened = true
                })

                $('.minibasket-btn-group').on('hide.bs.dropdown', function () {
                    that.opened = false
                })
            })
        }
    }
</script>
