<template>
    <div class="card product-card" :id="'product' + product.id">
        <a :href="detailUrl" class="card-img-holder text-center">
            <img :src="image" class="card-img-top img-fluid" :alt="productName">
        </a>

        <div class="card-body d-flex flex-column">
            <h4 class="card-title">
                <a :title="product.info.artno" :href="detailUrl" v-html="product.info.artno"></a>
            </h4>

            <span class="item-title">
                <a :title="productName" :href="detailUrl" v-html="productName"></a>
            </span>

            <div class="price" :class="{
                'loading': pricesLoading
                }">
                <template v-if="grossPrice !== null || (showRecommendedRetailPrice && rrpPrice !== null)">
                    <div v-if="showRecommendedRetailPrice">
                        <span class="SalePrice" v-if="rrpPrice !== null">
                            {{ formatPrice(rrpPrice) }} <span class="price-vat">{{ $dictionary('ordering.price.inc.rrp') }}</span>
                        </span>
                    </div>
                    <div v-else>
                        <span class="SalePrice" v-if="grossPrice !== null">
                            {{  formatPrice(grossPrice) }} <span class="price-vat">{{ $dictionary('ordering.price.vat') }}</span>
                        </span>
                    </div>
                </template>
                <template v-else-if="!pricesLoading">
                    <div class="loader-error">Error!</div>
                </template>
            </div>

            <add-to-basket
                v-bind="{
                    item: product.id,
                    basketidentifier,
                    disabled: ! orderable,
                    itemOrderable: orderable,
                    orderLimit: product.info.order_limit,
                    noPrice: !pricesLoading && this.noPrice,
                    minimalOrderQuantity: this.minimalOrderQuantity,
                    indivisibleUnit: this.indivisibleUnit
                }"
                ></add-to-basket>


            <item-stock-sales
                v-if="!hideStock"
                v-bind="{
                    artno: product.info.artno
                }"
            ></item-stock-sales>
        </div>
    </div>
</template>

<script>
    import currency from '../../js/helpers/currency'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            productJson: String,
            productName: String,
            detailUrl: String,
            image: String,
            basketidentifier: String,
            hideStock: Boolean,
            currency: String,
            minimalOrderQuantity: Number,
            indivisibleUnit: Number,
        },
        data() {
            return {
                noPrice: false
            }
        },
        methods: {
            formatPrice: function (price) {
                if (price == null || typeof price === 'undefined') {
                    return
                }

                return currency.formatPrice(price, this.price.currency)
            }
        },
        computed: {
            ...mapGetters('lister', [
                'prices',
                'pricesLoading'
            ]),
            product() {
                return JSON.parse(this.productJson)
            },
            price() {
                for (const priceObj of this.prices) {
                    if (priceObj.identifier === this.product.info.artno) {
                        return priceObj
                    }
                }

                return null
            },
            grossPrice() {
                if (this.price == null) {
                    this.noPrice = true

                    return null
                }

                if (! this.price.hasOwnProperty('price_inc')) {
                    this.noPrice = true

                    return null
                }

                if (this.price.price_inc === null) {
                    this.noPrice = true

                    return null
                }

                if (this.price.price_inc === 0) {
                    this.noPrice = true
                }

                return this.price.price_inc
            },
            rrpPrice() {
                if (this.price == null) {
                    this.noPrice = true

                    return null
                }

                return this.price.recommended_retail_price
            },
            showRecommendedRetailPrice() {
                return store.state.showRecommendedRetailPrice;
            },
            orderable() {
                return this.grossPrice !== null && this.grossPrice > 0
            }
        }
    }
</script>
