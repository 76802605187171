var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-totals" },
    [
      _c("h3", [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.summary_header"))),
      ]),
      _vm._v(" "),
      !_vm.isQuotation
        ? [
            _c("div", { staticClass: "row subtotal-price" }, [
              _c("div", { staticClass: "col-7 ml-lg-auto" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$dictionary("ordering.basket.subtotal"))),
                ]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$dictionary("ordering.basket.without_vat_text"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-5 text-right p-0 pr-3" },
                [
                  _vm.subtotal !== null
                    ? [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.formatPrice(_vm.subtotal)) +
                            "\n                "
                        ),
                      ]
                    : _vm.pricesLoading
                    ? [
                        _c("div", { staticClass: "loader" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "loader-error" }, [
                          _vm._v("Error!"),
                        ]),
                      ],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row subtotal-price" }, [
              _c("div", { staticClass: "col-7 ml-lg-auto" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$dictionary("ordering.basket.shipping_costs"))
                  ),
                ]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$dictionary("ordering.basket.without_vat_text"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-5 text-right p-0 pr-3" },
                [
                  _vm.shippingCosts !== null
                    ? [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.formatPrice(_vm.shippingCosts)) +
                            "\n                "
                        ),
                      ]
                    : _vm.pricesLoading
                    ? [
                        _c("div", { staticClass: "loader" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "loader-error" }, [
                          _vm._v("Error!"),
                        ]),
                      ],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.uplift !== null
              ? _c("div", { staticClass: "row subtotal-price" }, [
                  _c("div", { staticClass: "col-7 ml-lg-auto" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$dictionary("ordering.basket.uplift"))),
                    ]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.$dictionary("ordering.basket.without_vat_text")
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-5 text-right p-0 pr-3" }, [
                    _vm._v(_vm._s(_vm.formatPrice(_vm.uplift))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row total-price" }, [
              _c("div", { staticClass: "col-7 ml-lg-auto" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$dictionary("ordering.basket.total_vat"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-5 text-right p-0 pr-3" },
                [
                  _vm.totalVat !== null
                    ? [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.formatPrice(_vm.totalVat)) +
                            "\n                "
                        ),
                      ]
                    : _vm.pricesLoading
                    ? [
                        _c("div", { staticClass: "loader" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "loader-error" }, [
                          _vm._v("Error!"),
                        ]),
                      ],
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row grand-total" }, [
        _c("div", { staticClass: "col-7 ml-lg-auto" }, [
          _c("b", [
            _vm._v(_vm._s(_vm.$dictionary("ordering.basket.grandtotal"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-5 text-right p-0 pr-3" },
          [
            _vm.grandTotal !== null
              ? [_c("b", [_vm._v(_vm._s(_vm.formatPrice(_vm.grandTotal)))])]
              : _vm.pricesLoading
              ? [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])]
              : [
                  _c("div", { staticClass: "loader-error" }, [
                    _vm._v("Error!"),
                  ]),
                ],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }