<template>
    <div class="row result no-gutters">
        <div class="description col-lg-4">
            <h4 class="m-0">{{ item.productInfo.artno }}</h4>
            <p class="m-0">{{ item.productInfo.name }}</p>
        </div>
        <div class="quantity text-center col-lg-3">
            <add-to-basket
                v-bind="{
                    item: item.productInfo.identifier,
                    basketidentifier,
                    orderLimit: item.productInfo.order_limit,
                    disabled: false,
                    itemOrderable: orderable,
                    checkOrderLimit: true,
                    removePreLoader: true,
                    minimalOrderQuantity: item.productInfo.minimal_order_quantity,
                    indivisibleUnit: item.productInfo.indivisible_unit
                }"
                small="1"
            ></add-to-basket>
        </div>

        <div class="grossprice col-lg-2">
            <div v-if="showRecommendedRetailPrice">
                {{ formatPrice(rrpPrice) }}
            </div>
            <div v-else>
                <template v-if="unitPrice !== null">
                    {{ formatPrice(unitPrice) }}
                </template>
                <template v-else>
                    <div class="loader">Loading...</div>
                </template>
            </div>
        </div>

        <div class="orderable text-left text-md-center col-lg-2">
            <i class="icon" :class="{'icon-check-solid c-info-green': orderable, 'icon-times-solid c-info-red': !orderable}"></i>
        </div>
    </div>
</template>

<script>
import currency from '../../js/helpers/currency'
import api from "@/api";
import Axios from "axios";

export default {
        props: {
            basketidentifier: String,
            item: Object,
        },
        created() {
            this.getPrices();
        },
        computed: {
            orderable() {
                return this.grossPrice !== null;
            },
            showRecommendedRetailPrice() {
                return store.state.showRecommendedRetailPrice;
            },
        },
        data() {
            return {
                basketAmount: 0,
                grossPrice: null,
                unitPrice: null,
                rrpPrice: null,
                cancelToken: null,
                currency: null
            }
        },
        methods: {
            formatPrice: function(price) {
                return currency.formatPrice(price, this.currency)
            },
            getPrices() {
                if (this.cancelToken !== null) {
                    this.cancelToken.cancel()
                }
                this.cancelToken = Axios.CancelToken.source().token

                api.pricing.listPriceForProducts(this.item.productInfo.identifier, this.cancelToken)
                .then(({data}) => {
                    const price = data.data[0].price_inc;
                    if (price === 0) {
                        this.$emit('delete', this.item);
                    } else {
                        this.grossPrice = price;
                        this.unitPrice = data.data[0].unit_price;
                        this.rrpPrice = data.data[0].recommended_retail_price;
                        this.currency = data.data[0].currency;
                    }
                }).catch(() => {
                    this.$emit('delete', this.item);
                })
            },
        }
    }
</script>
